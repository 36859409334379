---
import { cn } from '@/utils'

interface Props {
  id?: string
  loadingClass?: string
  open?: boolean
}

const { id, loadingClass, open = false } = Astro.props
---

<dialog id={id} class={cn('modal', loadingClass)} open={open}>
  <div class="modal-box w-auto bg-white p-0">
    <lottie-player
      src="https://lottie.host/5bc7a36c-83a9-431f-ba37-a434590c04ba/3qBcDKFlQV.json"
      background="##ffffff"
      speed="1"
      style="width: 150px; height: 150px"
      loop
      autoplay
      direction="1"
      mode="normal"></lottie-player>
  </div>
</dialog>
