import { navigate } from 'astro:transitions/client'
import { createMemo } from 'solid-js'

import {
  CheckCircleIcon,
  FlashIcon,
  Image,
  Typography,
  type YourBooks,
} from '@/components'
import { trackButtonClick } from '@/utils/analytics'

interface BookCardItemProps {
  showFavoriteButton?: boolean
  handleClickFavorite?: (id: number | undefined) => void
  booksDetail: YourBooks
}

const BookLine = (props: BookCardItemProps) => {
  const bookHref = createMemo(() => {
    const bookId = props.booksDetail?.id
    return ['/book/detail', bookId].join('/')
  })

  return (
    <div class="flex w-full flex-col items-center gap-y-3 rounded-xl bg-pale-violet-10 p-6 md:flex-row">
      <div class="flex w-full flex-1 flex-col gap-4 md:flex-row">
        <Image
          class="px-3 md:w-[200px]"
          loading="lazy"
          srcSet={props.booksDetail.imageUrl}
          alt="Book Cover"
        />
        <div class="flex w-full flex-col items-center justify-center gap-1.5 md:flex-row md:items-center">
          <div class="flex w-full flex-col items-center justify-center gap-1.5 md:flex-col md:items-start">
            <div class="flex flex-row gap-2">
              {props.booksDetail.childBook.isAssigned && (
                <div class="flex flex-row items-center gap-2 rounded-lg bg-violet-60 px-2 py-1">
                  <FlashIcon color="white" width={16} height={16} />
                  <Typography
                    weight="semibold"
                    size="t-sm"
                    element="span"
                    class="text-center text-white"
                  >
                    Assigned
                  </Typography>
                </div>
              )}

              {props.booksDetail.childBook.progress === 100 && (
                <div class="flex flex-row items-center gap-2 rounded-lg bg-violet-20 px-2 py-1">
                  <CheckCircleIcon color="#212121" width={16} height={16} />
                  <Typography
                    weight="semibold"
                    size="t-sm"
                    element="span"
                    class="text-center text-edsm-neutral-100"
                  >
                    Completed
                  </Typography>
                </div>
              )}
            </div>

            <Typography
              weight="bold"
              size="t-lg"
              element="h3"
              class="text-center text-edsm-neutral-100"
            >
              {props.booksDetail.name}
            </Typography>

            <progress
              id="progress"
              class="h-4 w-full rounded-full bg-violet-10"
              value={props.booksDetail.childBook.progress}
              max="100"
            />
          </div>
          <button
            class="btn-primary-edsoma mt-4 md:ml-10 md:mt-10"
            onClick={() => {
              trackButtonClick('Read', { bookId: props.booksDetail.id })
              navigate(bookHref())
            }}
          >
            Read
          </button>
        </div>
      </div>
    </div>
  )
}

export default BookLine
