import {
  For,
  Show,
  createMemo,
  createEffect,
  createSignal,
  onCleanup,
  onMount,
} from 'solid-js'
import { useStore } from '@nanostores/solid'

import {
  AgeRangeDrawer,
  BadgeInput,
  BookCategoryDrawer,
  BookCardItem,
  Combobox,
  LoadingBooks,
  Typography,
  SearchInput,
  Image,
} from '@/components'
import { listAuthors, listBooks } from '@/core/infra/graphql'
import type { AgeRange, Author, Book, BookCategory } from '@/core/entities'
import { loadingAgeRanges } from '@/store'
import {
  getSearchStore,
  getSelectedAgeRangeStore,
  getSelectedAuthorStore,
  getSelectedBookCategoryStore,
} from '@/store/session'
import { cn } from '@/utils'
import CheckboxGroup from '../CheckboxGroup'
import { getLanguages } from '@/core/infra/graphql/api/language'

export type LibraryBook = Pick<
  Book,
  'id' | 'name' | 'author' | 'childBook' | 'imageUrl' | 'webBookUrl'
>

type fetchBooksPagination = {
  page: number
  perPage: number
}

export type Language = {
  id: number
  label: string
  value: string
}

type fetchBooksResponse = {
  results: LibraryBook[]
  total: number
  totalPages: number
}

async function fetchBooks(
  childId: number,
  selectedAges: AgeRange[] = [],
  selectedCategories: BookCategory[] = [],
  selectedLanguages: number[] = [],
  pagination: fetchBooksPagination,
  selectedAuthor?: Author,
  search?: string,
): Promise<fetchBooksResponse> {
  const filter: {
    childId: number
    ageRangeId?: number[]
    categoryId?: number[]
    author?: Author
    name?: string
    languageIds?: number[]
  } = { childId }

  const ages = selectedAges.length ? selectedAges?.map((age) => age.id) : null
  if (ages) filter['ageRangeId'] = ages
  const categories = selectedCategories.length
    ? selectedCategories?.map((category) => category.id)
    : null
  if (categories) filter['categoryId'] = categories
  if (selectedAuthor) filter['author'] = selectedAuthor
  if (selectedLanguages.length) filter['languageIds'] = selectedLanguages
  if (search) filter['name'] = ['%', search, '%'].join('')

  const { listBook } = await listBooks<LibraryBook>({
    fields: ['author', 'childBook', 'id', 'imageUrl', 'name', 'webBookUrl'],
    variables: {
      filter,
      pagination,
    },
    options: { childBook: ['isFavorite', 'progress'] },
  })

  return listBook
}

const AllBooks = (props: { childId: number }) => {
  const BOOKS_PER_PAGE = 20
  const loadingAgeRange = useStore(loadingAgeRanges)

  const { value: search, setValue: setSearch } = getSearchStore()
  const {
    value: selectedAgeRanges,
    clearSelectedAgeRanges,
    removeSelectedAgeRange,
  } = getSelectedAgeRangeStore()
  const { value: selectedAuthor, setValue: setSelectedAuthor } =
    getSelectedAuthorStore()
  const {
    value: selectedCategories,
    clearSelectedBookCategory,
    removeSelectedBookCategory,
  } = getSelectedBookCategoryStore()

  const [data, setData] = createSignal<fetchBooksResponse>({
    results: [],
    total: 0,
    totalPages: 0,
  } as fetchBooksResponse)
  const [authorList, setAuthorList] = createSignal<Author[]>([])
  const [languageList, setLanguagesList] = createSignal<Language[]>([])
  const [selectedLanguage, setSelectedLanguage] = createSignal<Language[]>([])
  const [page, setPage] = createSignal<number>(1)
  const [loadingFilters, setLoadingFilters] = createSignal<boolean>(true)
  const [loadingScroll, setLoadingScroll] = createSignal<boolean>(false)
  const [bottom, setBottom] = createSignal<HTMLDivElement>()
  const [searchAuthor, setSearchAuthor] = createSignal<string>('')
  const [authorComboboxRef, setAuthorComboboxRef] =
    createSignal<HTMLDivElement | null>(null)

  const filteredAuthorList = createMemo(() => {
    if (searchAuthor()) {
      return authorList().filter((author) =>
        author.toLowerCase().includes(searchAuthor().toLowerCase()),
      )
    }
    return authorList()
  })

  const hasApplyedFilters = createMemo(() => {
    return (
      selectedAgeRanges().length + selectedCategories().length > 0 ||
      search() ||
      selectedAuthor()
    )
  })

  const handleClickShowDrawer = (
    id: 'my-drawer-category' | 'my-drawer-agerange',
  ) => {
    document.getElementById(id)?.click()
  }

  const handleSetAuthor = (author: Author) => {
    setSelectedAuthor(author)
    authorComboboxRef()?.click()
  }

  const removeAllFilters = () => {
    clearSelectedAgeRanges()
    clearSelectedBookCategory()
    setSearch('')
    setSelectedAuthor('')
    setSelectedLanguages([])
  }

  const handleFetchBooks = async (
    page: number,
    loadingType: 'filters' | 'scroll',
  ): Promise<fetchBooksResponse | undefined> => {
    const bottomEl = bottom()

    try {
      if (loadingType === 'filters') {
        setLoadingFilters(true)
      } else {
        setLoadingScroll(true)
      }
      if (bottomEl) bottomEl.classList.add('hidden')

      const response = await fetchBooks(
        props.childId,
        selectedAgeRanges(),
        selectedCategories(),
        selectedLanguage().map((language) => language.id),
        {
          page,
          perPage: BOOKS_PER_PAGE,
        },
        selectedAuthor(),
        search(),
      )

      return response
    } catch (error) {
      console.error(error)
    } finally {
      if (loadingType === 'filters') {
        setLoadingFilters(false)
      } else {
        setLoadingScroll(false)
      }
      if (bottomEl) bottomEl.classList.remove('hidden')
    }
  }

  createEffect(() => {
    const bottomEl = bottom()

    if (loadingAgeRange() || !bottomEl) return

    handleFetchBooks(1, 'filters').then((response) => {
      if (!response) return
      setData(response)
      setPage(1)
    })
  })

  createEffect(() => {
    const bottomEl = bottom()
    if (!bottomEl) return

    const observer = new IntersectionObserver((entries) => {
      if (loadingScroll() || data().totalPages <= page()) return
      if (entries[0].isIntersecting) {
        handleFetchBooks(page() + 1, 'scroll').then((response) => {
          if (!response) return
          setData((prev) => ({
            ...response,
            results: [...prev.results, ...response.results],
          }))
          setPage((prev) => prev + 1)
        })
      }
    })
    observer.observe(bottomEl)
    onCleanup(() => {
      observer.disconnect()
    })
  })

  const handleFavoriteAction = (bookId: number) => {
    setData((prev): fetchBooksResponse => {
      if (!prev.results.length) return prev
      return {
        ...prev,
        results: prev.results.map((book) => {
          if (book.id === bookId) {
            return {
              ...book,
              childBook: {
                ...book.childBook,
                isFavorite: !book.childBook.isFavorite,
              },
            }
          }
          return book
        }),
      }
    })
  }

  onMount(() => {
    try {
      listAuthors().then((response) => {
        setAuthorList(response.listAuthors)
      })
      getLanguages({}).then((response) => {
        if (response.languageList) {
          const languages = response.languageList.map((language) => ({
            id: language.id,
            label: language.name,
            value: language.locale,
          }))

          setLanguagesList(languages)

          if (languages.length === 1) {
            setSelectedLanguages([languages[0].value])
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  })

  function setSelectedLanguages(languages: unknown[]) {
    const selecteds = languageList()
      .filter((language) => languages.includes(language.value))
      .map((language) => language)

    setSelectedLanguage(selecteds)
  }

  return (
    <>
      <div>
        <BookCategoryDrawer />
        <AgeRangeDrawer />
        <label for="my-drawer-category" class="h-fit w-fit" />
        <label for="my-drawer-agerange" class="h-fit w-fit" />
      </div>

      <div class="flex w-full flex-col items-center justify-center">
        <div class="flex w-full flex-col items-center gap-6 md:flex-row">
          <BadgeInput
            id="button-categories"
            items={selectedCategories()}
            itemTitleKey="name"
            placeholder="All Books"
            onClickContainer={() => handleClickShowDrawer('my-drawer-category')}
            onClickRemoveItem={(item: BookCategory) => {
              removeSelectedBookCategory(item)
            }}
          />

          <BadgeInput
            id="button-agerange"
            items={selectedAgeRanges()}
            itemTitleKey="range"
            placeholder="All Ages"
            onClickContainer={() => handleClickShowDrawer('my-drawer-agerange')}
            onClickRemoveItem={(item: AgeRange) => {
              removeSelectedAgeRange(item)
            }}
          />
        </div>

        <div class="mt-6 flex w-full flex-col items-center gap-6 md:flex-row">
          <div class="flex w-full flex-row gap-2 md:w-1/2">
            <Combobox
              class={cn(languageList().length > 1 ? 'w-1/2' : 'w-full')}
              title="Author:"
              placeholder="All Authors"
              value={selectedAuthor()}
              onSearch={setSearchAuthor}
              onValueChange={setSelectedAuthor}
              ref={setAuthorComboboxRef}
            >
              <For each={filteredAuthorList()}>
                {(item) => (
                  <div
                    class={cn(
                      'flex w-full flex-row items-center gap-2 rounded-2xl p-2',
                      'hover:bg-pale-violet-20 active:opacity-60',
                    )}
                    onClick={() => handleSetAuthor(item)}
                  >
                    <Typography
                      element="span"
                      size="t-lg"
                      class="whitespace-nowrap text-edsm-neutral-70"
                    >
                      {item}
                    </Typography>
                  </div>
                )}
              </For>
            </Combobox>

            {languageList().length > 1 && (
              <CheckboxGroup
                class="w-1/2"
                options={languageList()}
                selectedValues={selectedLanguage().map(
                  (language) => language.value,
                )}
                showedValues={selectedLanguage().map(
                  (language) => language.label,
                )}
                onValueChange={(selected) => setSelectedLanguages(selected)}
                placeholder="All Languages"
              />
            )}
          </div>

          <SearchInput
            containerClass="w-full md:w-1/2"
            placeholder="Search for a book"
            onSearch={setSearch}
            value={search()}
          />
        </div>

        <Show when={hasApplyedFilters()}>
          <div>
            <button
              class="btn-primary-edsoma mt-6 w-auto px-7"
              onClick={removeAllFilters}
            >
              Clear All
            </button>
          </div>
        </Show>

        <Show when={data().results.length}>
          <Typography
            element="h1"
            size="h-md"
            weight="bold"
            class="mb-6 mt-10 text-edsm-neutral-100"
          >
            {hasApplyedFilters() ? 'Your Book Selection' : 'All Books'}
          </Typography>
        </Show>

        <div class="flex w-full flex-row flex-wrap content-stretch items-center justify-center justify-center justify-items-center gap-6">
          {!loadingFilters() && (
            <For each={data().results}>
              {(book) => (
                <BookCardItem
                  showFavoriteButton
                  handleFavorite={handleFavoriteAction}
                  booksDetail={book as Book}
                />
              )}
            </For>
          )}
          <Show when={loadingScroll() || loadingFilters()}>
            <LoadingBooks />
          </Show>
        </div>

        <Show
          when={!data().results.length && !loadingFilters() && !loadingScroll()}
        >
          <div class="mt-8 flex w-full flex-col items-center justify-center gap-6">
            <Image
              srcSet="/crying-unicorn.svg"
              loading="lazy"
              alt="Crying unicorn"
            />
            <Typography
              element="span"
              size="t-xl"
              class="text-edsm-neutral-100"
            >
              No books found for this category and age range
            </Typography>
          </div>
        </Show>
      </div>
      <div ref={setBottom} />
    </>
  )
}

export default AllBooks
