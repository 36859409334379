import { splitProps, type Component, type ComponentProps } from 'solid-js'

import { cn } from '@/utils'

interface ImageProps extends ComponentProps<'img'> {}

const Image: Component<ImageProps> = (_props) => {
  const [props, rest] = splitProps(_props, ['class'])

  return (
    <img
      class={cn('', props.class)}
      {...rest}
      onError={(e) => {
        e.currentTarget.src = '/ed-sitting.svg'
      }}
    />
  )
}

export default Image
